import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Retailer } from 'src/app/retailer/state/retailer.actions';
import { CoreState, CoreStateModel } from '../../state/core.state';
import { Navigate } from '@ngxs/router-plugin';
import { LangService } from 'src/app/core/services/lang.service';
import { VersionCheckService } from 'src/app/version-check.service';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';
import { environment } from 'src/environments/environment';
import { DutyPharmService } from 'src/app/core/services/dutyPharm.service';
import { DutyPharms } from '../../models/duty-pharm';
import { Observable } from 'rxjs';
import { log } from 'node:console';

@Component({
  selector: 'app-screensaver',
  templateUrl: './screensaver.component.html',
  styleUrls: ['./screensaver.component.scss'],
})
export class ScreensaverComponent implements OnInit, OnDestroy, AfterViewInit {
  screenSaverType: string = 'standard';
  screenSaverMode: string = 'dark';
  theme: string;
  defaultLanguage: string;
  portrait: boolean;
  logo: string;
  screenSaverImageCenter: string = '';
  videoAds: string = '';
  screenSaverImage: string;
  modules: any;
  toBuyText: string = 'ZUM EINKAUFEN';
  clickText: string = 'BERÜHREN';
  textCapitalization: string = 'upercase';
  currentTime: Date = new Date();
  intervalId: any;
  currentApoName: string = '';
  currentApoPhone: string = '';
  currentApoWorkingHours: string = '';
  currentApoWorkingHoursSat: string = '';
  pharmacyInfo: any;
  dutyPharms: any = {};
  currentLang: string;
  public availableLanguages = [];
  showLangList: boolean = false;
  multiLanguage: boolean = false;
  centerImage: string;
  fullBgColour: boolean = false;
  // @ViewChild('videoContainer', { read: ElementRef }) videoContainer: ElementRef;
  // @ViewChild('videoContainer', { static: false }) videoContainer: ElementRef;
  // @ViewChild('videoPlayer') videoPlayer: ElementRef;

  @ViewChild('videoContainer', { read: ElementRef }) videoContainer: ElementRef;
  @ViewChild('videoPlayer', { read: ElementRef }) videoPlayer: ElementRef;

  constructor(
    private store: Store,
    private dutyPharmService: DutyPharmService,
    private langService: LangService,
    private versionCheckService: VersionCheckService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.currentLang = this.langService.getLanguage();
    this.availableLanguages = this.langService.getAvailableLanguages();
    console.warn('availableLanguages', this.availableLanguages);
    this.store.dispatch(new Retailer.Reset());
    this.currentLang = this.langService.getLanguage();
    console.log(this.currentLang);
    if (this.store.selectSnapshot<CoreStateModel>(CoreState).config != null) {
      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .screenSaverImageCenter != null &&
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .screenSaverImageCenter != ''
      ) {
        this.centerImage =
          this.store.selectSnapshot<CoreStateModel>(
            CoreState
          ).config.screenSaverImageCenter;
      } else {
        this.centerImage =
          this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;
      }

      this.logo =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.logo;

      this.screenSaverImageCenter =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.screenSaverImageCenter;
      this.videoAds =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.sSaverVideo;
      // this.toggleVideo();
      // this.setupVideo();
      this.multiLanguage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.multiLanguage;
      this.screenSaverImage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.screenSaverImage;
      this.theme =
        this.store.selectSnapshot<CoreStateModel>(CoreState).config.theme;
      this.screenSaverType =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.screenSaverType;
      this.screenSaverMode =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.screenSaverMode;
      this.defaultLanguage =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.defaultLanguage;
      localStorage.setItem('theme', this.theme);
      localStorage.setItem('logo', this.logo);
      localStorage.setItem('publicLanguage_derautomat', this.defaultLanguage);
      this.setDefaultLanguage(this.defaultLanguage);
      if (
        this.store.selectSnapshot<CoreStateModel>(CoreState).config
          .isOfficeButler
      ) {
        this.toBuyText = 'SCREENSAVER.TOBUYTEXT';
        this.clickText = 'SCREENSAVER.CLICKTEXT';
        this.textCapitalization = '';
      }

      this.pharmacyInfo =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.pharmacyInfo;
      this.fullBgColour =
        this.store.selectSnapshot<CoreStateModel>(
          CoreState
        ).config.fullBgColour;
      this.currentApoName = this.pharmacyInfo.name;
      this.currentApoPhone = this.pharmacyInfo.phone;
      this.currentApoWorkingHours = this.pharmacyInfo.working_hours;
      this.currentApoWorkingHoursSat = this.pharmacyInfo.working_hours_sat;
    }

    if (environment.production) {
      this.versionCheckService.checkVersion();
    }

    this.portrait = JSON.parse(localStorage.getItem('portrait')) ?? true;
    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config &&
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .vendingType === VendingTypes.SKI
    ) {
      this.store.dispatch(new Navigate(['/core', 'selection-buttons']));
    }

    // DEVTEST for development purpose only test screens
    setTimeout((x) => {
      // this.store.dispatch(new Navigate(['/retailer', 'selection']));
      // this.store.dispatch(new Navigate(['/core', 'initialization']));
      //this.store.dispatch(new Navigate(['/core', 'error']));
      // this.store.dispatch(new Navigate(['/retailer', 'info']));
      // this.store.dispatch(new Navigate(['/retailer', 'payment']));
      // this.store.dispatch(new Navigate(['/retailer', 'release']));
      //this.store.dispatch(new Navigate(['/retailer', 'retrieve']));
      // this.store.dispatch(new Navigate(['/retailer', 'print-bill']));
      //this.store.dispatch(new Navigate(['/retailer', 'retrieve-wwks', 'wwks']));
      // this.store.dispatch(new Navigate(['/retailer', 'finish']));
      // this.store.dispatch(new Navigate(['/retailer', 'finish-confirm']));
      //this.store.dispatch(new Navigate(['/retailer', 'pickup-detail']));
    }, 200);
  }

  ngOnInit(): void {
    //!luki
    console.log('Screensaver component initialized');
    setTimeout((x) => {
      this.store.dispatch(new Retailer.HealthCheck());
    }, 500);

    this.getDutyApo();
    this.intervalId = setInterval(() => {
      this.currentTime = new Date();
      this.getDutyApo();
    }, 30 * 60 * 1000);
  }
  ngAfterViewInit(): void {
    // console.warn('SADA AFTER VIEW INIT');
    this.loadVideo();
  }

  loadVideo() {
    if (this.videoAds) {
      // Set video source
      this.renderer.setAttribute(
        this.videoPlayer.nativeElement,
        'src',
        this.videoAds
      );
      // Append the video element to the component's native element
      this.renderer.appendChild(
        this.videoContainer.nativeElement,
        this.videoPlayer.nativeElement
      );
      this.videoPlayer.nativeElement.muted = true;

      setTimeout(() => {
        this.videoPlayer.nativeElement.play();
      }, 2000);
    }
  }

  stopAndRemoveVideo() {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.pause();
      this.videoPlayer.nativeElement.removeAttribute('src');
      this.renderer.removeChild(
        this.videoContainer.nativeElement,
        this.videoPlayer.nativeElement
      );
      console.warn('Video stopped and removed');
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.stopAndRemoveVideo();
  }

  toggleLanguageList() {
    this.showLangList = !this.showLangList;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event);
    this.start();
  }

  start() {
    console.log('screensaver clicked');
    this.modules =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.modules;

    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config
        .vendingType === VendingTypes.SKI
    ) {
      this.store.dispatch(new Navigate(['/core', 'selection-buttons']));
    } else if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.isOfficeButler
    ) {
      this.store.dispatch(new Navigate(['/core', 'login-user']));
    } else {
      if (this.isOnlyRentModule()) {
        this.store.dispatch(new Navigate(['/locker', 'rent', 'selection']));
      } else {
        this.store.dispatch(new Retailer.ActionSelection());
      }
    }
  }

  isOnlyRetailerModule(): boolean {
    return true; // this.modules.retailer && !this.modules.rent && !this.modules.dropoff;
  }

  isOnlyRentModule(): boolean {
    return this.modules.rent && !this.modules.retailer && !this.modules.dropoff;
  }

  setDefaultLanguage(defaultLanguage: string) {
    this.langService.setPublicLanguage(defaultLanguage);
    this.langService.getNewLanguage(defaultLanguage);
    this.langService.setLanguage();
    this.langService.emitChangeLang(defaultLanguage);
    this.currentLang = this.langService.getLanguage();
  }

  setPublicLanguage() {
    console.log('langService', this.langService.getLanguage());
    console.log('currentLang', this.currentLang);
    switch (this.currentLang) {
      case 'de':
        this.langService.setPublicLanguage('en');
        this.langService.getNewLanguage('en');
        this.langService.setLanguage();
        this.langService.emitChangeLang('en');
        break;
      case 'en':
        this.langService.setPublicLanguage('de');
        this.langService.getNewLanguage('de');
        this.langService.setLanguage();
        this.langService.emitChangeLang('de');
        break;

      default:
    }
    this.currentLang = this.langService.getLanguage();
  }

  // setPublicLanguage() {
  //   switch (this.currentLang) {
  //     case 'de':
  //       this.langService.setPublicLanguage('en');
  //       this.langService.getNewLanguage('en');
  //       this.langService.setLanguage();
  //       this.langService.emitChangeLang('en');
  //       break;
  //     case 'en':
  //       this.langService.setPublicLanguage('de');
  //       this.langService.getNewLanguage('de');
  //       this.langService.setLanguage();
  //       this.langService.emitChangeLang('de');
  //       break;

  //     default:

  //   }
  //   this.currentLang = this.langService.getLanguage();
  // }

  getDutyApo() {
    let machineId = localStorage.getItem('machineId');

    this.dutyPharmService.getDutyPharm(+machineId).subscribe({
      next: (dutyPharms: any) => {
        if (dutyPharms && dutyPharms.pharmacy_group_items.length > 0) {
          const pharmacyGroupItems = dutyPharms.pharmacy_group_items;
          this.dutyPharms = pharmacyGroupItems.slice(0, 4);
        }
      },
      error: (error) => console.error(error),
    });

    // const demoData =
    // {
    //   pharmacy_group_id: 40,
    //   pharmacy_group_name: "Blair Clark",
    //   priority: 2,
    //   pharmacy_group_items: [
    //     {
    //       name: "Gwendolyn Booth",
    //       address: "Magni at ut ea aut s",
    //       working_hours: "06-20"
    //     },
    //     {
    //       name: "Hayfa Martin",
    //       address: "Veritatis ut maiores",
    //       working_hours: "06-22"
    //     },
    //     {
    //       name: "Gwendolyn Booth",
    //       address: "Magni at ut ea aut s",
    //       working_hours: "06-20"
    //     },
    //     {
    //       name: "Hayfa Martin",
    //       address: "Veritatis ut maiores",
    //       working_hours: "06-22"
    //     },
    //     {
    //       name: "Gwendolyn Booth",
    //       address: "Magni at ut ea aut s",
    //       working_hours: "06-20"
    //     },
    //     {
    //       name: "Hayfa Martin",
    //       address: "Veritatis ut maiores",
    //       working_hours: "06-22"
    //     }
    //   ],
    //   date_from: "2023-04-26T12:20:00.424Z",
    //   date_to: "2023-04-30T12:20Z"
    // }

    // console.warn('demoData', demoData);
    // this.dutyPharms = demoData.pharmacy_group_items.slice(0, 4);
    // console.warn('this.dutyPharms', this.dutyPharms);
  }
}
